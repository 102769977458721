.CodeMirror pre.CodeMirror-line, .CodeMirror pre.CodeMirror-line-like {
    border-radius: 0;
    border-width: 0;
    background: 0 0;
    font-family: inherit;
    font-size: inherit;
    margin: 0;
    white-space: pre;
    word-wrap: normal;
    line-height: inherit;
    color: inherit;
    z-index: 2;
    position: relative;
    overflow: visible;
    -webkit-tap-highlight-color: transparent;
    font-variant-ligatures: contextual;
}

.file-input-container {
    position: relative;
    display: inline-block;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    cursor: pointer;
}

/* Estilo para ocultar el input de tipo file */
.file-input {
    display: none;
}

/* Estilos para mostrar el nombre del archivo seleccionado */
.file-input-container .file-name {
    font-size: 14px;
    color: #333;
}